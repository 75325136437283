export default [
  {
    title: 'layout.textDashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  }, {
    title: 'layout.textPersonInfo',
    route: 'person-infos',
    icon: 'UserIcon',
  }, {
    title: 'layout.textReminderEvents',
    route: 'reminder-events',
    icon: 'CalendarIcon',
  }, {
    title: 'layout.textShopeeVouchers',
    route: 'shopee-vouchers',
    icon: 'ArchiveIcon',
  }, {
    title: 'layout.textNumerologies',
    route: 'numerologies',
    icon: 'HashIcon',
  },
]
